/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */
 import axios from 'axios'

 import {
     LaunchStatusEvent,
     LaunchStatusType,
     ModelDefinition,
     PlatformNext,
     UndefinedModelDefinition,
     InputEmitter,
     DefaultStreamerOptions,
     StreamerStatus
 } from '@pureweb/platform-sdk';
 
 import {
     useStreamer,
     useLaunchRequest,
     IdleTimeout,
     LaunchRequestOptions,
     VideoStream,
     System
 } from '@pureweb/platform-sdk-react';
 
 import * as qs from 'query-string';
 import React, { useEffect, useState, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Icon } from 'semantic-ui-react';
 import useAsyncEffect from 'use-async-effect';
 import './App.css';
 import clientConfig from './client.json';
 
 import { LaunchView } from './Launch';
 import logger from './Log';
 import { isMobile } from "react-device-detect";
 //import { ethers } from "ethers";
 
 const client: ClientJson = clientConfig as ClientJson;
 
 var mob = (`${isMobile}`);
 console.log("MOBILE " + mob);
 
 class ClientJson {
     environmentId?: string;
     launchType?: string;
     projectId?: string;
     modelId?: string;
     version?: string;
     endpoint?: string;
     usePointerLock?: boolean;
     pointerLockRelease?: boolean;
     useNativeTouchEvents?: boolean;
 }
 
 class ClientOptions {
     // Overridable connection options
     LaunchType?: string;
 
     // Launch queue configuration
     ProjectId?: string;
     ModelId?: string;
     Version?: string;
     EnvironmentId?: string;
     Endpoint?: string;
 
     // Overridable streamer options
     ForceRelay = false;
     UseNativeTouchEvents?: boolean;
     UsePointerLock?: boolean;
     PointerLockRelease?: boolean;
 
     isValid(): boolean {
         if (!this.ProjectId) {
             return false;
         }
         if (!this.ModelId) {
             return false;
         }
         return true;
     }
 }
 
 
 
 interface LoadingProps {
     LaunchRequestStatus: LaunchStatusEvent;
     StreamerStatus: StreamerStatus;
 }
 
 declare global {
     interface Window {
         ethereum: {
             request<T>(params: { method: string }): Promise<T>;
             on<T>(event: string, cb: (params: T) => void): void;
             removeListener<T>(event: string, cb: (params: T) => void): void;
             selectedAddress: string | undefined;
         };
     }
 }
 
 var globaldatamt = {
     address: "",
     Balance: ""
 };
 
 
 const LoadingView: React.FC<LoadingProps> = (props: LoadingProps) => {
 
     if (props.StreamerStatus === StreamerStatus.Connected || props.StreamerStatus === StreamerStatus.Completed) {
 
         if (isMobile) {
 
             return <>
                 <div className='metamaskpanel'>
                     {/*<button className="mtbutton" onClick={btnhandler} >Metamask Ethereum</button>*/}
                     <div>Account: <span className="showAccount">{globaldatamt.address.substring(0, 4) + "..." + globaldatamt.address.substring(38)}</span></div>
                 </div>
             </>;
         } else {
             return <>
 
                 <div className='metamaskpanel'>
                     {/*<button className="mtbutton" onClick={btnhandler} >Metamask Ethereum</button>*/}
                     <div>Account: <span className="showAccount">{globaldatamt.address.substring(0, 4) + "..." + globaldatamt.address.substring(38)}</span></div>
                 </div>
                 {/*
       <div className="absoluta displaykeycontrols" style={{ textAlign: 'center', position: 'absolute', top: '0px'  }}>
       <div className="dirKey">DIRECTION  KEY</div>
       <div className="wasd">
         <div className="wasd W ">W</div>
         <div className="wasd A">A</div>
         <div className="wasd S">S</div>
         <div className="wasd D">D</div>
       </div>
         
       <div>
       <div className="jumpKey">JUMP KEY </div>
       <div className="wasd">
         <div className="space">SPACE</div> 
       </div>
       </div>
       </div>
       <div />
       */}
             </>;
 
         }
     }
 
     let content;
 
     if (props.StreamerStatus === StreamerStatus.NotSupported) {
         content = (
             <div>
                 <h3>Your browser does not support the necessary WebRTC capabilities.</h3>
             </div>
         );
     }
     if (
         props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
         props.LaunchRequestStatus.status === LaunchStatusType.Error ||
         props.StreamerStatus === StreamerStatus.Failed
     ) {
         content = (
             <div>
                 <h3>The experience is presently unavailable.</h3>
                 <h3>Please refresh to request a new session.</h3>
             </div>
         );
     } else {
         content = (
             <div className="div_zindex">
                 <img src="loading.gif" alt="Logo metazooie" width="190" height="124"></img>
                 <h3>Please wait, your experience is loading.</h3>
                 <h4>This may take up to 60 seconds.</h4>
                 {/*<h4>Join the SoN community in the meantime.</h4>
                 <div className="svg_div">
                     <a className="a_svg" href="https://discord.gg/mAPxewFFwY" target="_blank"><svg width="59" height="46" viewBox="0 0 59 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M37.8583 0C37.2735 1.05355 36.7522 2.14159 36.2975 3.25821C31.8294 2.54748 27.278 2.54748 22.8099 3.25821C22.3552 2.14159 21.834 1.05355 21.2491 0C17.0402 0.723325 12.9486 2.01872 9.08667 3.85061C2.1807 13.8749 -0.951205 26.0448 0.25197 38.1803C4.75248 41.5832 9.80362 44.1786 15.1826 45.8519C16.4074 44.2246 17.5005 42.5011 18.4514 40.698C16.6965 40.0472 15.0097 39.2237 13.4157 38.2395C13.8529 37.9469 14.2664 37.6201 14.6525 37.2621C19.3009 39.5141 24.3942 40.6836 29.5537 40.6836C34.7133 40.6836 39.8065 39.5141 44.4549 37.2621C44.8672 37.6175 45.2795 37.9433 45.6917 38.2395C44.089 39.2159 42.4039 40.0485 40.656 40.7276C41.5674 42.5716 42.6313 44.3353 43.8365 46C49.2087 44.3325 54.2511 41.7365 58.7376 38.3284C59.9697 26.1907 56.8353 14.0108 49.903 3.99871C46.0844 2.12891 42.0332 0.783956 37.8583 0V0ZM19.7766 31.2492C18.303 31.1428 16.9276 30.4663 15.9392 29.3617C14.9508 28.2571 14.4263 26.8104 14.4758 25.3252C14.4189 23.838 14.9408 22.3871 15.9306 21.2809C16.9205 20.1747 18.3003 19.5003 19.7766 19.4012C21.253 19.5003 22.6328 20.1747 23.6226 21.2809C24.6125 22.3871 25.1344 23.838 25.0775 25.3252C25.1344 26.8124 24.6125 28.2632 23.6226 29.3695C22.6328 30.4757 21.253 31.1501 19.7766 31.2492V31.2492ZM39.3308 31.2492C37.8571 31.1428 36.4817 30.4663 35.4933 29.3617C34.505 28.2571 33.9805 26.8104 34.03 25.3252C33.973 23.838 34.4949 22.3871 35.4848 21.2809C36.4746 20.1747 37.8544 19.5003 39.3308 19.4012C40.8099 19.4929 42.1941 20.1651 43.1855 21.273C44.1769 22.3809 44.6961 23.836 44.6316 25.3252C44.6961 26.8143 44.1769 28.2694 43.1855 29.3774C42.1941 30.4853 40.8099 31.1575 39.3308 31.2492V31.2492Z" fill="white" />
                     </svg></a>
                     <a className="a_svg" href="https://twitter.com/soulsofnature_" target="_blank"><svg width="56" height="46" viewBox="0 0 56 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M56 5.45644C53.9406 6.37851 51.7284 7.00153 49.4026 7.28313C51.8023 5.83223 53.5977 3.54867 54.4536 0.858565C52.199 2.21173 49.7315 3.16422 47.1583 3.67461C45.4279 1.80771 43.1359 0.570297 40.6382 0.15449C38.1404 -0.261318 35.5767 0.167741 33.3451 1.37505C31.1134 2.58237 29.3387 4.50039 28.2963 6.83133C27.254 9.16227 27.0025 11.7757 27.5807 14.2659C23.0124 14.0341 18.5433 12.8343 14.4635 10.7444C10.3837 8.65442 6.78442 5.721 3.89923 2.13451C2.91271 3.85404 2.34546 5.8477 2.34546 7.97094C2.34436 9.88235 2.81019 11.7645 3.70163 13.4503C4.59307 15.1362 5.88254 16.5737 7.45565 17.6352C5.63126 17.5765 3.84713 17.0784 2.25174 16.1823V16.3318C2.25156 19.0127 3.16929 21.611 4.84921 23.6859C6.52914 25.7609 8.86779 27.1847 11.4683 27.7156C9.77591 28.1785 8.00154 28.2466 6.27922 27.915C7.01294 30.2217 8.44216 32.2388 10.3668 33.684C12.2914 35.1291 14.6151 35.93 17.0126 35.9744C12.9428 39.2026 7.91657 40.9538 2.74254 40.9461C1.82601 40.9463 0.910256 40.8922 0 40.7841C5.25195 44.1962 11.3656 46.007 17.6094 46C38.7457 46 50.3004 28.3112 50.3004 12.97C50.3004 12.4716 50.288 11.9682 50.2658 11.4698C52.5134 9.82747 54.4534 7.79375 55.9951 5.46392L56 5.45644V5.45644Z" fill="white" />
                     </svg></a>
                 </div>*/}
             </div>
         );
     }
     return (
         <div
             style={{
                 position: 'absolute',
                 left: '50%',
                 top: '50%',
                 transform: 'translate(-50%, -50%)',
                 zIndex: '999'
             }}>
             <div style={{ textAlign: 'center' }}>{content}</div>
         </div>
     );
 };
 
 interface ViewProps {
     LaunchRequestStatus: LaunchStatusEvent;
     StreamerStatus: StreamerStatus;
     VideoStream: MediaStream;
     InputEmitter: InputEmitter;
     UseNativeTouchEvents: boolean;
     UsePointerLock: boolean;
     PointerLockRelease: boolean;
 }
 
 const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
     const videoRef = useRef<HTMLVideoElement>(null);
     const handle = useFullScreenHandle();
     props.InputEmitter.EmitUIInteraction(mob);
 
     //create  wallet
     //let wallet = globaldatamt.address ;
     //array 
     let wallet = { type: 'wallet', address: globaldatamt.address };
 
     //send a wallet to Unity
     props.InputEmitter.EmitUIInteraction(wallet);
     //console.log(wallet);
     //console.log(globaldatamt.address.substring(0, 4) + "..." + globaldatamt.address.substring(38));
 
 
     // Fullscreen API presently supported on iPad, but not iPhone or iPod
     const isIPhone = System.Browser().os === 'iOS' && !window.navigator.userAgent.includes('iPad');
     return (
         <div style={{ height: '100%' }}>
             <FullScreen handle={handle}>
                 <IdleTimeout
                     Status={props.StreamerStatus}
                     WarningThreshold={300}
                     ExitThreshold={120}
                     WarningCallback={handle.exit}
                     ExitCallback={() => window.location.reload()} // TODO: How to 'close' a contribution?
                 />
 
                 <LoadingView LaunchRequestStatus={props.LaunchRequestStatus} StreamerStatus={props.StreamerStatus} />
                 <VideoStream
                     VideoRef={videoRef}
                     Emitter={props.InputEmitter}
                     Stream={props.VideoStream}
                     UseNativeTouchEvents={props.UseNativeTouchEvents}
                     UsePointerLock={props.UsePointerLock}
                     PointerLockRelease={props.PointerLockRelease}
                 />

                 <Button
                     onClick={() => props.InputEmitter.EmitUIInteraction('Main')}
                     style={{ position: 'absolute', top: 10, right: 370 }}>
                     <Icon name="eye" />
                     Main
                 </Button>

                 <Button
                     onClick={() => props.InputEmitter.EmitUIInteraction('Gameplay_Map1_Level')}
                     style={{ position: 'absolute', top: 10, right: 250 }}>
                     <Icon name="eye" />
                     Level 1
                 </Button>

                 <Button
                     onClick={() => props.InputEmitter.EmitUIInteraction("Gameplay_Map2_Level")}
                     style={{ position: 'absolute', top: 10, right: 130 }}>
                     <Icon name="tint" />
                     Level 2
                 </Button>

                 <Button
                     onClick={() => props.InputEmitter.EmitUIInteraction("Gameplay_Map3_Level")}
                     style={{ position: 'absolute', top: 10, right: 10 }}>
                     <Icon name="tint" />
                     Level 3
                 </Button>

             </FullScreen>
         </div>
     );
 };
 
 // Initialize audio.
 // load() must be called from a user interaction, especially to retain iOS audio
 // this can be 'mouseup', 'touchend' or 'keypress'
 // Pass the audioStream created from useStreamer as the srcObject to play game audio.
 const audio = new Audio();
 audio.autoplay = true;
 audio.volume = 0.5;
 
 // Parse query parameters
 const query = qs.parse(window.location.search);
 const clientOptions: ClientOptions = new ClientOptions();
 clientOptions.LaunchType = (query['launchType'] as string) ?? client.launchType;
 clientOptions.Endpoint = (query['endpoint'] as string) ?? client.endpoint;
 clientOptions.ProjectId = (query['projectId'] as string) ?? client.projectId;
 clientOptions.ModelId = (query['modelId'] as string) ?? client.modelId;
 clientOptions.Version = (query['version'] as string) ?? client.version;
 clientOptions.EnvironmentId = (query['environmentId'] as string) ?? client.environmentId;
 // use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
 clientOptions.UsePointerLock =
     (query['usePointerLock'] === undefined ? client.usePointerLock : query['usePointerLock'] === 'true') ?? true;
 // release the pointer lock on mouse up if true
 clientOptions.PointerLockRelease =
     (query['pointerLockRelease'] === undefined ? client.pointerLockRelease : query['pointerLockRelease'] === 'true') ??
     true;
 
 clientOptions.ForceRelay = query['forceRelay'] !== undefined ?? false;
 clientOptions.UseNativeTouchEvents =
     (query['useNativeTouchEvents'] === undefined
         ? client.useNativeTouchEvents
         : query['useNativeTouchEvents'] === 'true') ?? false;
 // Initialize platform reference
 const platform = new PlatformNext();
 platform.initialize({ endpoint: clientOptions.Endpoint || 'https://api.pureweb.io' });
 
 const App: React.FC = () => {
     const [modelDefinitionUnavailable, setModelDefinitionUnavailable] = useState(false);
     const [modelDefinition, setModelDefinition] = useState(new UndefinedModelDefinition());
     const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
     const [launchRequestError, setLaunchRequestError] = useState<Error>();
     const streamerOptions = DefaultStreamerOptions;
 
     useAsyncEffect(async () => {
         if (clientOptions.ProjectId) {
             logger.info('Initializing available models: ' + clientOptions.ProjectId);
             try {
                 await platform.useAnonymousCredentials(clientOptions.ProjectId, clientOptions.EnvironmentId);
                 await platform.connect();
                 logger.info('Agent Connected: ' + platform.agent.id);
                 streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers as RTCIceServer[];
                 streamerOptions.forceRelay = clientOptions.ForceRelay;
                 const models = await platform.getModels();
                 setAvailableModels(models);
                 logger.debug('Available models', models);
             } catch (err) {
                 logger.error(err);
             }
         }
     }, [clientOptions]);
 
     useEffect(() => {
         if (availableModels?.length) {
             const selectedModels = availableModels.filter(function (model: ModelDefinition): boolean {
                 if (clientOptions.ModelId === model.id) {
                     // If there is a version specified and we encounter it
                     if (clientOptions.Version && clientOptions.Version === model.version) {
                         return true;
                     }
                     // If there is no version specified and we find the primary version
                     if (!clientOptions.Version && model.active) {
                         return true;
                     }
                 }
                 return false;
             });
             if (selectedModels?.length) {
                 setModelDefinition(selectedModels[0]);
             } else {
                 setModelDefinitionUnavailable(true);
             }
         }
     }, [availableModels]);
 
     const launchRequestOptions: LaunchRequestOptions = {
         regionOverride: query['regionOverride'] as string,
         virtualizationProviderOverride: query['virtualizationProviderOverride'] as string
     };
     const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(platform, modelDefinition, launchRequestOptions);
     const [streamerStatus, emitter, videoStream, audioStream, messageSubject] = useStreamer(
         platform,
         launchRequest,
         streamerOptions
     );
     const [loading, setLoading] = useState(false);
 
     useEffect(() => {
         if (streamerStatus === StreamerStatus.Failed) {
             platform.disconnect();
         }
     }, [streamerStatus]);
 
     if (audioStream) {
         audio.srcObject = audioStream;
     }
 
     const launch = async () => {
         setLoading(true);
         audio.load();
 
         if (clientOptions.LaunchType !== 'local') {
             try {
                 await queueLaunchRequest();
             } catch (err) {
                 setLaunchRequestError(err);
             }
         }
     };
 
     // Log status messages
     useEffect(() => {
         logger.info('Status', status, streamerStatus);
     }, [status, streamerStatus]);
 
     // Subscribe to game messages
     useEffect(() => {
         const subscription = messageSubject.subscribe(
             (value: string) => {
                 logger.info('Message: ' + value);
             },
             (err) => {
                 logger.error(err);
             }
         );
 
         return () => {
             subscription.unsubscribe();
         };
     }, [messageSubject]);
 
     useEffect(() => {
         const subscription = messageSubject.subscribe(
             (value: string) => {
 
                 const message = JSON.parse(value);
 
                 if (message.hasOwnProperty("url")) {
                     window.open(message.url);
                 }
             },
             (err: any) => {
                 console.error(err);
             }
         );
 
         return () => {
             subscription.unsubscribe();
         };
 
     }, [messageSubject]);
 
     // usetstate for storing and retrieving wallet details
     const [data, setdata] = useState({
         address: "",
         Balance: "",
     });
 
 
 
     // Button handler button for handling a
     // request event for metamask
     const btnhandler = () => {
 
         if (navigator.userAgent.match(/Android/i)
             || navigator.userAgent.match(/webOS/i)
             || navigator.userAgent.match(/iPhone/i)
             || navigator.userAgent.match(/iPad/i)
             || navigator.userAgent.match(/iPod/i)
             || navigator.userAgent.match(/BlackBerry/i)
             || navigator.userAgent.match(/Windows Phone/i)) {

                 document.getElementsByClassName("centerwconbtn")[0].classList.add("hidemtbtn");
                 viewplaybutton();
 
         } else {

             document.getElementsByClassName("centerwconbtn")[0].classList.add("hidemtbtn");
             viewplaybutton();

         }
     };
 
     // getbalance function for getting a balance in
     // a right format with help of ethers
     /*const getbalance = (address="") => {
     
       // Requesting balance method
       window.ethereum
         .request({ 
           method: "eth_getBalance"
         })
         .then((balance) => {
           // Setting balance
           data.Balance = ethers.utils.formatEther(balance+"");
         });
     };*/
 
     // Function for getting handling all events
     const accountChangeHandler = (account = "") => {
         // Setting an address data
         data.address = account;
         globaldatamt = data;
         // Setting a balance
         //getbalance(account);
     };
 
     // Notify user of missing or errors in configuration
     if (!clientOptions.isValid()) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <p>
                     Your client has one or more configuration errors. Please consult the{' '}
                     <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client"> README </a> for details on how
                     to configure the client template.
                 </p>
             </div>
         );
     }
 
     if (modelDefinitionUnavailable) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <span>The model that you have requested does not exist</span>
             </div>
         );
     }
 
     if (launchRequestError) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <span>
                     {process.env.NODE_ENV === 'development'
                         ? `There was an error with the launch request: ${launchRequestError}`
                         : 'It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available.'}
                 </span>
             </div>
         );
     }
 
     // Begin connection
     if (streamerStatus === StreamerStatus.Disconnected) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <h2>Disconnected from stream</h2>
             </div>
         );
     }
 
     if (streamerStatus === StreamerStatus.Failed) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <h2>Failure during stream</h2>
                 <h2>Please refresh to request a new session</h2>
             </div>
         );
     }
 
     if (streamerStatus === StreamerStatus.Withdrawn) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <h2>Streamer contribution withdrawn</h2>
             </div>
         );
     }
 
 
 
     if (loading) {
 
         return (
             <>
                 <EmbeddedView
                     VideoStream={videoStream}
                     StreamerStatus={streamerStatus as StreamerStatus}
                     LaunchRequestStatus={status}
                     InputEmitter={emitter}
                     UseNativeTouchEvents={clientOptions.UseNativeTouchEvents!}
                     UsePointerLock={clientOptions.UsePointerLock!}
                     PointerLockRelease={clientOptions.PointerLockRelease!}
                 />
             </>
         );
     } else if (clientOptions.LaunchType !== 'local' && !availableModels) {
         return (
             <div className="init_meta"
                 style={{
                     display: 'flex',
                     height: '100vh',
                     width: '100vw',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <h2>Initializing...</h2>
             </div>
         );
     } else if (clientOptions.LaunchType !== 'local' && !availableModels?.length) {
         return (
             <div
                 style={{
                     display: 'flex',
                     height: '100%',
                     overflow: 'none',
                     textAlign: 'center',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                 <h2>No models are currently available in this environment.</h2>
             </div>
         );
     } else {
 
         //hideplaybutton();
 
         return <>
             <div className="centerwconbtn">
                 <div className="son_botw_background"></div>
                 <div className="son_botw_inner">
                     <img className="son_botw_logo_left" src="logostr3m.png" alt="Logo Str3m" />
                     {/* <img className="son_botw_logo" src="logo.png" alt="Logo Metazooie"></img> */}
                     <div className="son_botw_play"> <LaunchView Launch={launch} /></div>
                     <div className="son_botw_social">
                         {/* <a href="https://discord.gg/mAPxewFFwY" target="_blank"><svg width="59" height="46" viewBox="0 0 59 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M37.8583 0C37.2735 1.05355 36.7522 2.14159 36.2975 3.25821C31.8294 2.54748 27.278 2.54748 22.8099 3.25821C22.3552 2.14159 21.834 1.05355 21.2491 0C17.0402 0.723325 12.9486 2.01872 9.08667 3.85061C2.1807 13.8749 -0.951205 26.0448 0.25197 38.1803C4.75248 41.5832 9.80362 44.1786 15.1826 45.8519C16.4074 44.2246 17.5005 42.5011 18.4514 40.698C16.6965 40.0472 15.0097 39.2237 13.4157 38.2395C13.8529 37.9469 14.2664 37.6201 14.6525 37.2621C19.3009 39.5141 24.3942 40.6836 29.5537 40.6836C34.7133 40.6836 39.8065 39.5141 44.4549 37.2621C44.8672 37.6175 45.2795 37.9433 45.6917 38.2395C44.089 39.2159 42.4039 40.0485 40.656 40.7276C41.5674 42.5716 42.6313 44.3353 43.8365 46C49.2087 44.3325 54.2511 41.7365 58.7376 38.3284C59.9697 26.1907 56.8353 14.0108 49.903 3.99871C46.0844 2.12891 42.0332 0.783956 37.8583 0V0ZM19.7766 31.2492C18.303 31.1428 16.9276 30.4663 15.9392 29.3617C14.9508 28.2571 14.4263 26.8104 14.4758 25.3252C14.4189 23.838 14.9408 22.3871 15.9306 21.2809C16.9205 20.1747 18.3003 19.5003 19.7766 19.4012C21.253 19.5003 22.6328 20.1747 23.6226 21.2809C24.6125 22.3871 25.1344 23.838 25.0775 25.3252C25.1344 26.8124 24.6125 28.2632 23.6226 29.3695C22.6328 30.4757 21.253 31.1501 19.7766 31.2492V31.2492ZM39.3308 31.2492C37.8571 31.1428 36.4817 30.4663 35.4933 29.3617C34.505 28.2571 33.9805 26.8104 34.03 25.3252C33.973 23.838 34.4949 22.3871 35.4848 21.2809C36.4746 20.1747 37.8544 19.5003 39.3308 19.4012C40.8099 19.4929 42.1941 20.1651 43.1855 21.273C44.1769 22.3809 44.6961 23.836 44.6316 25.3252C44.6961 26.8143 44.1769 28.2694 43.1855 29.3774C42.1941 30.4853 40.8099 31.1575 39.3308 31.2492V31.2492Z" fill="white" />
                         </svg></a>
                         <a href="https://twitter.com/soulsofnature_" target="_blank"><svg width="56" height="46" viewBox="0 0 56 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M56 5.45644C53.9406 6.37851 51.7284 7.00153 49.4026 7.28313C51.8023 5.83223 53.5977 3.54867 54.4536 0.858565C52.199 2.21173 49.7315 3.16422 47.1583 3.67461C45.4279 1.80771 43.1359 0.570297 40.6382 0.15449C38.1404 -0.261318 35.5767 0.167741 33.3451 1.37505C31.1134 2.58237 29.3387 4.50039 28.2963 6.83133C27.254 9.16227 27.0025 11.7757 27.5807 14.2659C23.0124 14.0341 18.5433 12.8343 14.4635 10.7444C10.3837 8.65442 6.78442 5.721 3.89923 2.13451C2.91271 3.85404 2.34546 5.8477 2.34546 7.97094C2.34436 9.88235 2.81019 11.7645 3.70163 13.4503C4.59307 15.1362 5.88254 16.5737 7.45565 17.6352C5.63126 17.5765 3.84713 17.0784 2.25174 16.1823V16.3318C2.25156 19.0127 3.16929 21.611 4.84921 23.6859C6.52914 25.7609 8.86779 27.1847 11.4683 27.7156C9.77591 28.1785 8.00154 28.2466 6.27922 27.915C7.01294 30.2217 8.44216 32.2388 10.3668 33.684C12.2914 35.1291 14.6151 35.93 17.0126 35.9744C12.9428 39.2026 7.91657 40.9538 2.74254 40.9461C1.82601 40.9463 0.910256 40.8922 0 40.7841C5.25195 44.1962 11.3656 46.007 17.6094 46C38.7457 46 50.3004 28.3112 50.3004 12.97C50.3004 12.4716 50.288 11.9682 50.2658 11.4698C52.5134 9.82747 54.4534 7.79375 55.9951 5.46392L56 5.45644V5.45644Z" fill="white" />
                         </svg></a>*/}
                         <p>Powered by Metazooie Studios</p>
                     </div>
                 </div>
             </div> 
         </>;
     }
 };
 
 const AppWrapper: React.FC = () => {
     return System.IsBrowserSupported() ? (
         <App />
     ) : (
         <div className="ui red segment center aligned basic">
             <h2 className="header">Your browser is currently unsupported</h2>
         </div>
     );
 };
 
 function getcode() {
     document.getElementsByClassName('getcode')[0].classList.remove('hidden');
 }
 
 document.addEventListener("keydown", event => {
     /*if (event.isComposing || event.keyCode === 229) {
       return;
     }*/
     // do something
     //console.log(event);
 
     var kc = document.getElementsByClassName("absoluta");
     //console.log(kc);
 
     if (kc.length > 0) {
 
         kc[0].classList.remove("displaykeycontrols");
         kc[0].classList.add("hidekeycontrols");
     }
 });
 
 function hideplaybutton() {
     setTimeout(function () {
         document.getElementsByClassName("lcontent")[0].classList.add("hidden");
 
     }, 50);
 }
 
 function viewplaybutton() {
     document.getElementsByClassName("accessgranted")[0].classList.remove("hidden");
     document.getElementsByClassName("lcontent")[0].classList.remove("hidden");
     document.getElementsByClassName("launchContainer")[0].classList.add("topadjust");
 }
 
 function viewplaybutton2() {
     viewplaybutton();
     document.getElementsByClassName("centerwconbtn")[0].classList.add("hidemtbtn");
 }
 
 function verifycode() {
 
 
     var codeaccess = document.getElementsByClassName('verifycode').item(0) as HTMLInputElement | null;
     //console.log(codeaccess?.value);
     if (codeaccess?.value == "3522") {
         viewplaybutton2();
     } else {
         document.getElementsByClassName('sendcodeerr')[0].classList.remove('hidden');
     }
 }
 
 function viewinpvc() {
     document.getElementsByClassName('vcdiv')[0].classList.remove('hidden');
 }
 
 
 export default AppWrapper;